import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { calculateRem } from "../../../../utils/javascript-styles"

const StyledTitle = styled.h1`
  font-family: "Poppins", sans-serif;

  font-size: ${calculateRem(72)};
  color: ${props =>
    props.secondary ? props.theme.mineshaft : props.theme.white};
  display: block;
  margin: ${props => (props.collapse ? "0" : "0 0 2rem 0")};
  @media screen and (max-width: 39.9375em) {
    font-size: ${calculateRem(44)};
    text-align: center;
  }
`

const Title = ({ secondary, children }) => (
  <StyledTitle secondary={secondary}>{children}</StyledTitle>
)

Title.defaultProps = {
  secondary: false,
}
Title.propTypes = {
  secondary: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default Title

import styled from "styled-components"
import { Link } from "gatsby"

import { calculateRem } from "../../../../utils/javascript-styles"

const LinkButton = styled(Link)`
  border: 2px solid ${props => props.theme.mineshaft};
  color: ${props => props.theme.mineshaft};
  margin-bottom: 1rem;
  padding: ${calculateRem(10)} ${calculateRem(25)};
  transition: all 200ms;
  text-decoration: none;
  &:hover {
    background-color: ${props => props.theme.mineshaft};
    color: white;
  }
`

export default LinkButton

import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { calculateRem } from "../../../../utils/javascript-styles"

function setColor(theme, type) {
  let bgColor = null
  switch (type) {
    case "light":
      bgColor = theme.white
      break
    case "dark":
      bgColor = theme.thunder
      break
    case "alternate":
      bgColor = theme.merlot
      break
    default:
      bgColor = theme.white
      break
  }
  return bgColor
}

const StyledSubTitle = styled.h3`
  color: ${props => setColor(props.theme, props.type)};
  font-family: "Poppins", san-serif;
  font-size: ${calculateRem(32)};
  display: block;
  text-align: center;
  font-weight: 100;
  & span {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
  }
  @media screen and (max-width: 39.9375em) {
    font-size: ${calculateRem(22)};
    text-align: center;
  }
`

const SubTitle = ({ children, type }) => {
  return (
    <StyledSubTitle type={type}>
      <strong>{children}</strong>
    </StyledSubTitle>
  )
}
SubTitle.defaultProps = {
  type: "light",
}
SubTitle.propTypes = {
  type: PropTypes.oneOf(["light", "dark", "alternate"]),
  children: PropTypes.node,
}

export default SubTitle

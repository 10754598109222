import styled from "styled-components"

import { calculateRem } from "../../../../utils/javascript-styles"

const Button = styled.button`
  background-color: transparent;
  border-radius: 0;
  border: 2px solid
    ${props => (props.secondary ? props.theme.mineshaft : props.theme.white)};
  color: ${props =>
    props.secondary ? props.theme.mineshaft : props.theme.white};
  margin-bottom: 1rem;
  padding: ${calculateRem(10)} ${calculateRem(25)};
  transition: all 200ms;
  width: ${props => (props.fluid ? "100% !important" : "initial")};
  &:hover {
    cursor: pointer;
    color: ${props =>
      props.secondary ? props.theme.white : props.theme.mineshaft};
    background-color: ${props =>
      props.secondary ? props.theme.mineshaft : props.theme.white};
  }

  /* Small only */
  @media screen and (max-width: 39.9375em) {
    width: ${props => (props.expand === "sm" ? "100%" : "initial")};
  }

  /* Medium only */
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    width: ${props => (props.expand === "md" ? "100%" : "initial")};
  }

  /* Large and up */
  @media screen and (min-width: 64em) {
    width: ${props => (props.expand === "lg" ? "100%" : "initial")};
  }
`

export default Button

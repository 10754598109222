import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledStyledText = styled.a`
  background-color: transparent;
  color: ${props => props.theme.violetta};
  display: ${props => props.display};
  text-align: ${props => props.align};
  font-family: "Open Sans";
  margin-top: ${props => (props.collapse ? 0 : "default")};
  margin-right: ${props => (props.collapse ? 0 : "default")};
  margin-bottom: ${props => (props.collapse ? 0 : "default")};
  margin-left: ${props => (props.collapse ? 0 : "default")};
  transition: all 250ms;
  text-align: ${props => props.align};
  text-decoration: none;
  :hover {
    color: ${props => props.theme.easternBlue};
    cursor: pointer;
  }

  @media screen and (max-width: 39.9375em) {
    text-align: ${props => props.alignSmall};
  }
`

const StyledLink = ({
  children,
  align,
  display,
  alignSmall,
  alternate,
  collapse,
}) => (
  <StyledStyledText
    align={align}
    alignSmall={alignSmall}
    alternate={alternate}
    collapse={collapse}
    display={display}
  >
    {children}
  </StyledStyledText>
)

StyledLink.defaultProps = {
  align: "initial",
  alignSmall: "left",
  alternate: false,
  collapse: false,
  display: "block",
}
StyledLink.propTypes = {
  align: PropTypes.string,
  alignSmall: PropTypes.oneOf(["left", "center", "right"]),
  alternate: PropTypes.bool,
  collapse: PropTypes.bool,
  display: PropTypes.oneOf(["block", "inline-block"]),
  children: PropTypes.node.isRequired,
}

export default StyledLink

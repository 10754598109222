import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { calculateRem } from "../../../../utils/javascript-styles"

const StyledText = styled.p`
  color: ${props =>
    props.alternate ? props.theme.mineshaft : props.theme.white};
  font-family: "Roboto", san-serif;
  font-size: ${calculateRem(18)};
  display: block;
  text-align: ${props => props.align};
  font-weight: 100;
`

const Text = ({ alternate, align, children }) => {
  return (
    <StyledText alternate={alternate} align={align}>
      {children}
    </StyledText>
  )
}
Text.defaultProps = {
  alternate: false,
  align: "left",
}
Text.propTypes = {
  alternate: PropTypes.bool,
  align: PropTypes.oneOf(["left", "center", "right"]),
  children: PropTypes.node,
}

export default Text

import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { calculateRem } from "../../../../utils/javascript-styles"

const defaultProps = {
  align: "left",
}
const propTypes = {
  align: PropTypes.oneOf(["left", "right", "center"]),
}

const StyledLeadTitle = styled.p`
  color: ${props => (props.secondary ? "white" : "default")};
  font-family: "Poppins", sans-serif;
  font-size: ${calculateRem(28)};
  font-weight: bold;
  letter-spacing: ${calculateRem(2)};
  text-align: ${props => props.align};
  text-transform: uppercase;
`

const LeadTitle = ({ children, align }) => {
  return <StyledLeadTitle align={align}>{children}</StyledLeadTitle>
}

LeadTitle.defaultProps = defaultProps
LeadTitle.propTypes = propTypes

export default LeadTitle

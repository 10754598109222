import styled from "styled-components"
import { calculateRem } from "../../../../utils/javascript-styles"

const Flower = styled.img`
  height: ${props => props.height};
  margin-bottom: ${calculateRem(32)};
  position: relative;
  top: ${props => (props.pushUp ? "-48px" : "0")};
`

export default Flower

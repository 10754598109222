import React from "react"
import { navigate } from "gatsby"
import { Row, Col } from "react-styled-flexboxgrid"
import { LeadText, SubTitle } from "../../ui/atoms/Typography"
import { StyledGrid, Space } from "../../templates/Layout"
import { Section } from "../../ui/molecules/Section"

import { Button } from "../../ui/atoms/CTA"
import Flower from "../../ui/atoms/Flower"
import ImgFlower from "../../../images/chopsticks-flower.svg"

const Thanks = () => {
  return (
    <Section type="primary" centered>
      <StyledGrid>
        <Row>
          <Col xs={12}>
            <Flower src={ImgFlower} alt="logo flower design" height={150} />
            <SubTitle type="alternate" align="center">
              Thank you!
            </SubTitle>
            <LeadText type="dark" align="center">
              We will be contacting you shortly
            </LeadText>
            <Space mb={32} />
            <Button onClick={() => navigate("/")}>Return Home</Button>
          </Col>
        </Row>
      </StyledGrid>
    </Section>
  )
}

export default Thanks

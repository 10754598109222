import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { calculateRem } from "../../../../utils/javascript-styles"

function setColor(theme, type) {
  let bgColor = null
  switch (type) {
    case "dark":
      bgColor = theme.thunder
      break
    case "alternate":
      bgColor = theme.lightning
      break
    default:
      bgColor = theme.white
  }
  return bgColor
}

const StyledLeadText = styled.h4`
  color: ${props => setColor(props.theme, props.type)};
  font-family: "Poppins", san-serif;
  font-size: ${calculateRem(22)};
  display: block;
  text-align: ${props => props.align};
  font-weight: 100;
`

const LeadText = ({ children, type, align }) => {
  return (
    <StyledLeadText type={type} align={align}>
      <strong>{children}</strong>
    </StyledLeadText>
  )
}
LeadText.defaultProps = {
  type: "light",
  align: "left",
}
LeadText.propTypes = {
  children: PropTypes.node,
  align: PropTypes.oneOf(["left", "center", "right"]),
  type: PropTypes.oneOf(["light", "dark", "alternate"]),
}

export default LeadText

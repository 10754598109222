import styled from "styled-components"
import { calculateRem } from "../../../../utils/javascript-styles"

const Base = styled.section`
  padding-top: ${calculateRem(125)};
  padding-right: ${calculateRem(16)};
  padding-bottom: ${calculateRem(125)};
  padding-left: ${calculateRem(16)};
  /* Small only */
  @media screen and (max-width: 39.9375em) {
  }
`

export default Base

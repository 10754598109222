import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Row, Col } from "react-styled-flexboxgrid"
import { calculateRem } from "../../../../utils/javascript-styles"

function setBackground(theme, type) {
  let bgColor = null
  switch (type) {
    case "primary":
      bgColor = theme.white
      break
    case "secondary":
      bgColor = theme.gallery
      break
    case "tertiary":
      bgColor = theme.merlot
      break
    case "dark":
      bgColor = theme.diesel
      break
    case "gradient":
      bgColor = `linear-gradient(0deg, rgba(125,19,34,1) 0%, rgba(18,0,0,1) 100%);`
      break
    default:
      bgColor = theme.white
  }
  return bgColor
}

const StyledSection = styled.section`
  text-align: ${props => (props.textCentered ? "center" : "initial")};
  background: ${props => setBackground(props.theme, props.type)};
  padding-top: ${calculateRem(125)};
  padding-right: ${calculateRem(16)};
  padding-bottom: ${calculateRem(125)};
  padding-left: ${calculateRem(16)};
  /* Small only */
  @media screen and (max-width: 39.9375em) {
    padding: ${calculateRem(64)} ${calculateRem(16)};
  }
`

const Section = ({ children, type, centered }) => {
  return (
    <StyledSection type={type} textCentered={centered}>
      <Row>
        <Col xs={12}>{children}</Col>
      </Row>
    </StyledSection>
  )
}

Section.propTypes = {
  type: PropTypes.oneOf([
    "primary",
    "secondary",
    "tertiary",
    "gradient",
    "dark",
  ]),
}

export default Section
